/* eslint-disable */
// console.info(__dirname );
import config from '@/conf/Config.js';
import md5 from "md5";
import LZString from 'lz-string';
import { useToast } from "vue-toastification";
import moment from 'moment';

export default {
    data() {
        return {
            appName: config.appName,
            appVersi: config.versi,
            perPage: config.perpage,
            toast: useToast(),
            apiUrl: localStorage.urlSipjaki == undefined ? config.apiUrl : this.DeCompresString(localStorage.urlSipjaki) == '' ? config.apiUrl : this.DeCompresString(localStorage.urlSipjaki),
            appiSocket: config.appiSocket,
            moment: moment
        }
    },
    created: function () {
        this.moment.suppressDeprecationWarnings = true;
    },
    methods: {
        loadData(storeObj, kondisi, fn) {
            const self = this;
            storeObj.loading = true;
            let vparams = {
                operator: storeObj.filters.find.length > 0 ? storeObj.filters.operator : "AND",
                kondisi: "",
                field: "",
                fieldvalue: "",
                limit: storeObj.pages.batas,
                offset: storeObj.pages.batas * (storeObj.pages.halaman - 1),
                order: storeObj.filters.order,
                fieldselect: storeObj.filters.fieldselect,
                group: storeObj.filters.group
            };

            if (kondisi != undefined) {
                if (kondisi["field"] != undefined) {
                    vparams.kondisi = kondisi["kondisi"];
                    vparams.field = kondisi["field"];
                    vparams.fieldvalue = kondisi["fieldvalue"];
                }
            }

            if (storeObj.filters.find.length > 0) {
                storeObj.filters.find.map(function (value) {
                    vparams.kondisi += (vparams.kondisi !== "" ? ";" : "") + value.kondisi;
                    vparams.field += (vparams.field !== "" ? ";" : "") + value.field;
                    if (vparams.fieldvalue !== "null") {
                        vparams.fieldvalue += (vparams.fieldvalue !== "" ? ";" : "") + value.fieldValue;
                    } else {
                        vparams.fieldvalue += (vparams.fieldvalue !== "null" ? ";" : "") + value.fieldValue;
                    }
                });
            }

            self.apiPost(`${storeObj.url}search`, vparams, function (response) {
                if (response.success === true) {
                    const res = self.recsToJson(response.recs);
                    console.info(self.showLog("Isi Response " + storeObj.url), self.showLog(res));
                    storeObj.setRecs(res);
                    storeObj.pages.ttlrec = parseFloat(response.ttlrec);
                    storeObj.pages.ttlhalaman = Math.ceil(parseFloat(response.ttlrec) / storeObj.pages.batas);
                    if (fn != undefined) {
                        storeObj.loading = false;
                        fn(res);
                    }
                } else {
                    // eslint-disable-next-line no-undef
                    vm.toast.error("Load Gagal " + response.error["Message"], "Load");
                }
                storeObj.loading = false;
            });
        },
        recsToJson(recs) {
            const hsl = LZString.decompressFromBase64(recs);
            const hasiljson = JSON.parse(hsl)
            return hasiljson == null ? [] : hasiljson;
        },
        string2Base64(str) {
            return LZString.compressToBase64(str);
        },
        base642String(str) {
            return LZString.decompressFromBase64(str);
        },
        StringCompress(str) {
            return LZString.compress(str);
        },
        DeCompresString(str) {
            return LZString.decompress(str);
        },
        setParams(tbl, params) {
            if (localStorage.sipjakiDel == '0') {
                if (params != undefined) {
                    if (params != {}) {
                        params["kondisi"] += `;=`;
                        params["field"] += `;${tbl}.del`;
                        params["fieldvalue"] += `;0`;
                    } else {
                        params["operator"] = "AND";
                        params["kondisi"] = `=`;
                        params["field"] = `${tbl}.del`;
                        params["fieldvalue"] = `0`;
                    }
                } else {
                    params["operator"] = "AND";
                    params["kondisi"] = `=`;
                    params["field"] = `${tbl}.del`;
                    params["fieldvalue"] = `0`;
                }
            }
            return params;
        },
        setStatus(dt, title) {
            if (dt === "T") {
                if (title == undefined) {
                    title = "Active";
                }
                return "<div class='w3-green w3-center w3-round' style='min-width:60px;margin-top:5px;'>" + title + "</div>";
            }
            if (title == undefined) {
                title = "Non Active";
            }
            return "<div class='w3-red w3-center w3-round' style='min-width:60px;margin-top:5px;'>" + title + "<div>";
        },
        checkLogin(success = function (dt) { }, errorFn = function (dt) { }) {
            console.info("Proses Check login 1", localStorage.tokenDeSiJaKoB);
            console.info("Proses Check login 2", this.DeCompresString(localStorage.tokenDeSiJaKoB));
            let myHeaders = new Headers();
            myHeaders.append("authorization", (localStorage.tokenDeSiJaKoB == undefined ? "-" : this.DeCompresString(localStorage.tokenDeSiJaKoB)));

            let requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow'
            };

            fetch(config.apiUrl + "/tproject/admin/checklogin", requestOptions).then((response) => {
                console.info("Check Login 1", response);
                if (!response.ok) {
                    if (response.status == 401) {
                        vm.$router.push({ name: "Login" });
                        return response
                            .text()
                            .then((err) => Promise.reject(err));
                    }
                    try {
                        return response
                            .json()
                            .then((err) => Promise.reject(err));
                    } catch (e) {
                        return response
                            .text()
                            .then((err) => Promise.reject(err));
                    }
                }
                return response.json();
            })
                .then(success)
                .catch(errorFn);
        },
        // Untuk Electron
        compressImage(file, fn) {
            const self = this;
            const MAX_WIDTH = 1000;
            const MAX_HEIGHT = 1000;
            const MIME_TYPE = "image/jpeg";
            const QUALITY = 1;
            // const file = ev.target.files[0]; // get the file
            const blobURL = URL.createObjectURL(file);
            const img = new Image();
            img.src = blobURL;
            img.onerror = function () {
                URL.revokeObjectURL(this.src);
                // Handle the failure properly
                // console.info("Cannot load image");
                fn("Cannot load image");
            };
            return img.onload = function () {
                URL.revokeObjectURL(this.src);
                const [newWidth, newHeight] = self.calculateSize(img, MAX_WIDTH, MAX_HEIGHT);
                const canvas = document.createElement("canvas");
                canvas.width = newWidth;
                canvas.height = newHeight;
                const ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, newWidth, newHeight);
                // fn(canvas);
                canvas.toBlob(async function (blob) {
                    let fileupload = new File([blob], file.name, { type: "image/jpeg" });
                    fn(fileupload);
                }, MIME_TYPE, QUALITY);
            };
        },
        displayInfo(label, file) {
            const p = document.createElement('p');
            p.innerText = `${label} - ${this.readableBytes(file.size)}`;
            document.getElementById('root').append(p);
        },

        readableBytes(bytes) {
            const i = Math.floor(Math.log(bytes) / Math.log(1024)),
                sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

            return (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i];
        },
        calculateSize(img, maxWidth, maxHeight) {
            let width = img.width;
            let height = img.height;

            // calculate the width and height, constraining the proportions
            if (width > height) {
                if (width > maxWidth) {
                    height = Math.round((height * maxWidth) / width);
                    width = maxWidth;
                }
            } else {
                if (height > maxHeight) {
                    width = Math.round((width * maxHeight) / height);
                    height = maxHeight;
                }
            }
            return [width, height];
        },
        slice(array, start, end) {
            let length = array === null ? 0 : array.length;
            if (!length) {
                return [];
            }
            start = start === null ? 0 : start;
            end = end === undefined ? length : end;
            if (start < 0) {
                start = -start > length ? 0 : length + start;
            }
            end = end > length ? length : end;
            if (end < 0) {
                end += length;
            }
            length = start > end ? 0 : (end - start) >>> 0;
            start >>>= 0;
            let index = -1;
            const result = new Array(length);
            while (++index < length) {
                result[index] = array[index + start];
            }
            return result;
        },
        setFilterHeaders(orders) {
            const self = this;
            orders.fields.map(function (val) {
                if (val.sort.asc != "-") {
                    if (val.sort.asc == "asc") {
                        val.sort.asc = "-";
                    } else if (val.sort.asc == "desc") {
                        val.sort.asc = "asc";
                    }
                    self.setFilterHeader(val, orders);
                }
            });
        },
        setFilterHeader(dt, order) {
            if (dt['sort']['status']) {
                let filter = dt.fieldFind === '' ? dt.key : dt.fieldFind;
                filter = filter + ' ' + dt['sort']['asc'];
                order['order'] = order['order'].replace("," + filter, '');
                order['order'] = order['order'].replace(filter, '');
                if (order['order'][0] == ',') {
                    order['order'] = order['order'].substring(1, order['order'].length);
                }
                dt['sort']['asc'] = (dt['sort']['asc'] == 'asc') ? 'desc' : ((dt['sort']['asc'] == '-') ? 'asc' : '-');
                filter = dt.fieldFind === '' ? dt.key : dt.fieldFind;
                filter = filter + ' ' + dt['sort']['asc'];
                if (dt['sort']['asc'] != '-') {
                    if (order['order'] != '') {
                        order['order'] = order['order'] + "," + filter;
                    } else {
                        order['order'] += filter;
                    }
                }
            } else {
                return false;
            }
        },
        formatDate(tgl) {
            let ex = tgl.split("-");
            return `${ex[2]}-${ex[1]}-${ex[0]}`;
        },
        async getNowFromServer() {
            let obj = await this.apiAsync("/tproject/admin/getnow", {});
            return obj.now;
        },
        getNow(format) {
            let today = new Date();
            let dd = String(today.getDate()).padStart(2, '0');
            let mm = String(today.getMonth() + 1).padStart(2, '0');
            let yyyy = today.getFullYear();
            if (format == 'id') {
                today = `${dd}-${mm}-${yyyy}`;
            } else {
                today = `${yyyy}-${mm}-${dd}`;
            }
            return today;
        },
        showLog(msg) {
            if (process.env.NODE_ENV == 'production') {
                return "";
            }
            return msg;
        },
        showLoading(title) {
            this.setTextLoading(title);
            document.getElementById('idLoading').style.display = 'block';
        },
        setTextLoading(title) {
            document.getElementById('txt-loading').innerHTML = title;
        },
        hideLoading() {
            document.getElementById('idLoading').style.display = 'none';
        },
        getValid(rec, rules) {
            // console.info(rec,rules);
            let isValid = true;
            let self = this;
            for (let dt in rec) {
                // console.info(rec[dt]);
                let field = self.findObj(rules, "key", dt);
                // console.info(dt, field);
                if (field != undefined) {
                    if (field["required"]) {
                        // console.info('required', field);
                        if (["String", "text", "select"].includes(field.type)) {
                            if (rec[dt].length == 0) {
                                isValid = false;
                                // console.info(self.setToast('Error', `${field.title} Tidak boleh kosong`  ,'',300));
                                rules["msg"] = `${field.name} Tidak boleh kosong`
                                self.toast.error(rules["msg"]);
                            } else if (rec[dt].length < field["min-length"]) {
                                isValid = false;
                                rules["msg"] = `${field.name} harus lebih besar ${field["min-length"]} huruf`
                                self.toast.error(rules["msg"]);
                                // console.info(`${field.name} harus lebih besar ${field["min-length"]} huruf`  );
                            } else if (rec[dt].length > field["max-length"]) {
                                isValid = false;
                                rules["msg"] = `${field.name} harus lebih besar dari ${field["max-length"]} huruf`;
                                self.toast.error(rules["msg"]);
                                // console.info(`${field.name} harus lebih besar dari ${field["max-length"]}`  );
                            }
                        } else {
                            if (rec[dt].length == 0) {
                                isValid = false;
                                rules["msg"]`${field.name} Tidak boleh kosong`;
                                self.toast.error(rules["msg"]);
                                // console.info(self.setToast('Error', `${field.title} Tidak boleh kosong`  ,'',300));
                            }
                        }
                    }
                }
            }
            return isValid;
        },
        setToast(title, content, icon, width) {
            icon = (icon == '' ? '' : icon);
            width = (width == '' ? '200' : width);
            let hd = (icon != "") ? `<div class="w3-col l2">
            <i class="fa ${icon}"></i>
        </div>`: '';
            let str = `<div class="w3-row" style="width:${width}px;">
                ${hd}
                <div class="w3-rest">
                    <b><u>${title}</u></b>
                    <div>${content}</div>
                </div>
            </div>`;
            return str;
        },
        firstUpperCase(kata) {
            return kata.charAt(0).toUpperCase() + kata.slice(1)
        },
        toDateServer: function (value) {
            let data = value.split("/");
            return data[2] + "-" + data[1] + "-" + data[0];
        },
        apiGetParams(params) {
            let keys = Object.keys(params);
            let parameters = "";
            keys.forEach(function (key) {
                // console.info(params[key])
                let value = params[key];
                parameters += `${key}=${value}&`
            });
            return parameters;
        },
        makeId(length) {
            let result = '';
            let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@#$&';
            let charactersLength = characters.length;
            for (let i = 0; i < length; i++) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            return result;
        },
        async getCode() {
            // let obj = await axios.post(`/tproject/admin/getcode`);
            // return obj.data.key;
            return md5(Math.random().toString() + 'mbahsomo');
        },
        async setTokenData() {
            // if (localStorage.tokenDeSiJaKoB == undefined) {
            if (localStorage.statuslogin != "T") {
                let obj = await this.apiAsync("/gettoken", {});
                localStorage.tokenDeSiJaKoB = this.StringCompress(this.base642String(obj.token));
            }
            // }
        },
        async apiPost(url, params, success = function (dt) { }, errorFn = function (dt) { }) {
            if (params == undefined) {
                params = {};
            }
            if (url.substring(0, 1) != "/") {
                if (url.substring(0, 4) != "http") {
                    url = "/" + url;
                }
            }
            console.info(this.showLog(url), this.showLog(params));

            let urlServer = (this.DeCompresString(localStorage.urlSipjaki) == undefined ? config.apiUrl : this.DeCompresString(localStorage.urlSipjaki) == '' ? config.apiUrl : this.DeCompresString(localStorage.urlSipjaki)) + url;
            if (url.substring(0, 4) == "http") {
                urlServer = url;
            }
            fetch(urlServer, {
                method: "POST",
                headers: { authorization: this.DeCompresString(localStorage.tokenDeSiJaKoB), "Content-Type": "application/json" },
                body: LZString.compressToBase64(JSON.stringify(params)),
            })
                .then((response) => {
                    if (!response.ok) {
                        if (response.status === 401) {
                            vm.$router.push({ name: "Login" });
                            return response
                                .text()
                                .then((err) => Promise.reject(err));
                        }
                        try {
                            return response
                                .json()
                                .then((err) => Promise.reject(err));
                        } catch (e) {
                            return response
                                .text()
                                .then((err) => Promise.reject(err));
                        }
                    }
                    return response.json();
                })
                .then(success)
                .catch(errorFn);
        },
        async apiPostZip(url, params, success = function (dt) { }, errorFn = function (dt) { }) {
            if (params == undefined) {
                params = {};
            }
            if (url.substring(0, 1) != "/") {
                if (url.substring(0, 4) != "http") {
                    url = "/" + url;
                }
            }
            console.info(this.showLog(url), this.showLog(params));

            let urlServer = (this.DeCompresString(localStorage.urlSipjaki) == undefined ? config.apiUrl : this.DeCompresString(localStorage.urlSipjaki) == '' ? config.apiUrl : this.DeCompresString(localStorage.urlSipjaki)) + url;
            if (url.substring(0, 4) == "http") {
                urlServer = url;
            }
            fetch(urlServer, {
                method: "POST",
                headers: { authorization: this.DeCompresString(localStorage.tokenDeSiJaKoB), "Content-Type": "application/json", "zip": "F" },
                body: JSON.stringify(params),
            })
                .then((response) => {
                    if (!response.ok) {
                        if (response.status === 401) {
                            vm.$router.push({ name: "Login" });
                            return response
                                .text()
                                .then((err) => Promise.reject(err));
                        }
                        try {
                            return response
                                .json()
                                .then((err) => Promise.reject(err));
                        } catch (e) {
                            return response
                                .text()
                                .then((err) => Promise.reject(err));
                        }
                    }
                    return response.json();
                })
                .then(success)
                .catch(errorFn);
        },
        async apiPostServer(url, params, success = function (dt) { }, errorFn = function (dt) { }) {
            if (params == undefined) {
                params = {};
            }
            if (url.substring(0, 1) != "/") {
                url = "/" + url;
            }
            console.info(this.showLog(url), this.showLog(params));
            // console.info(this.showLog(this.DeCompresString(localStorage.tokenDeSiJaKoB)));
            fetch(config.apiUrl + url, {
                method: "POST",
                headers: { authorization: this.DeCompresString(localStorage.tokenDeSiJaKoB), "Content-Type": "application/json" },
                body: LZString.compressToBase64(JSON.stringify(params)),
            })
                .then((response) => {
                    if (!response.ok) {
                        if (response.status === 401) {
                            vm.$router.push({ name: "Login" });
                            return response
                                .text()
                                .then((err) => Promise.reject(err));
                        }
                        try {
                            return response
                                .json()
                                .then((err) => Promise.reject(err));
                        } catch (e) {
                            return response
                                .text()
                                .then((err) => Promise.reject(err));
                        }
                    }
                    return response.json();
                })
                .then(success)
                .catch(errorFn);
        },

        async apiAsync(url, params) {
            if (params == undefined) {
                params = {};
            }
            if (url.substring(0, 1) != "/") {
                if (url.substring(0, 4) != "http") {
                    url = "/" + url;
                }
            }
            // console.info(this.showLog(url),this.showLog(params));
            // let urlServer = (localStorage.urlSipjaki==undefined ? config.apiUrl : this.DeCompresString(localStorage.urlSipjaki)=='' ? config.apiUrl : this.DeCompresString(localStorage.urlSipjaki)) + url;
            // if (url.substring(0,4)=="http"){
            //     urlServer = url;
            // }
            let response = await fetch(config.apiUrl + url, {
                method: "POST",
                headers: { authorization: this.DeCompresString(localStorage.tokenDeSiJaKoB), "Content-Type": "application/json" },
                body: LZString.compressToBase64(JSON.stringify(params)),
            });

            if (!response.ok) {
                if (response.status === 401) {
                    vm.$router.push({ name: "Login" });
                    return response
                        .text()
                        .then((err) => Promise.reject(err));
                }
                try {
                    return response
                        .json()
                        .then((err) => Promise.reject(err));
                } catch (e) {
                    return response
                        .text()
                        .then((err) => Promise.reject(err));
                }
            }
            let obj = await response.json();
            return obj;
        },

        async apiAsyncServer(url, params) {
            if (params == undefined) {
                params = {};
            }
            if (url.substring(0, 1) != "/") {
                url = "/" + url;
            }
            // console.info(this.showLog(url),this.showLog(params));
            try {
                // console.info('response awal');
                let response = await fetch(config.apiUrl + url, {
                    method: "POST",
                    headers: { authorization: this.DeCompresString(localStorage.tokenDeSiJaKoB), "Content-Type": "application/json" },
                    body: LZString.compressToBase64(JSON.stringify(params)),
                });
                // console.info('response', response);

                if (!response.ok) {
                    if (response.status === 401) {
                        vm.$router.push({ name: "Login" });
                        return response
                            .text()
                            .then((err) => Promise.reject(err));
                    }
                    try {
                        return response
                            .json()
                            .then((err) => Promise.reject(err));
                    } catch (e) {
                        return response
                            .text()
                            .then((err) => Promise.reject(err));
                    }
                }
                let obj = await response.json();
                return obj;
            } catch (error) {
                console.info('error', error);
                return error;
            }
        },
        apiUpload: function (url, file, param, callback = function (json) { }, error = function (json) { }, progress = function (evt) { }) {
            let request = new XMLHttpRequest();
            let formData = new FormData();
            formData.append("file", file);
            if (param != '') {
                param.map(function (value) {
                    formData.append(value.key, value.val)
                })
            }

            request.open("POST", this.apiUrl + url);
            request.onreadystatechange = function () {
                if (request.readyState === 4) {
                    if (request.status === 200 || request.status === 201) {
                        callback(JSON.parse(request.responseText));
                    } else {
                        error(request.responseText);
                    }
                }
            };
            request.setRequestHeader("authorization", this.DeCompresString(localStorage.tokenDeSiJaKoB));
            request.upload.addEventListener("progress", progress);
            request.send(formData);
        },

        async deDownloadFile(url, params, success = function (dt) { }, errorFn = function (dt) { }) {
            if (params == undefined) {
                params = {};
            }
            if (url.substring(0, 1) != "/") {
                if (url.substring(0, 4) != "http") {
                    url = "/" + url;
                }
            }
            // console.info(this.showLog(url),this.showLog(params));
            let urlServer = (localStorage.urlSipjaki == undefined ? config.apiUrl : this.DeCompresString(localStorage.urlSipjaki) == '' ? config.apiUrl : this.DeCompresString(localStorage.urlSipjaki)) + url;
            if (url.substring(0, 4) == "http") {
                urlServer = url;
            }
            fetch(urlServer, {
                method: "POST",
                headers: { authorization: this.DeCompresString(localStorage.tokenDeSiJaKoB), "Content-Type": "application/json" },
                body: LZString.compressToBase64(JSON.stringify(params)),
            })
                .then((response) => {
                    if (!response.ok) {
                        if (response.status === 401) {
                            return response
                                .text()
                                .then((err) => Promise.reject(err));
                        }
                        try {
                            return response
                                .json()
                                .then((err) => Promise.reject(err));
                        } catch (e) {
                            return response
                                .text()
                                .then((err) => Promise.reject(err));
                        }
                    }
                    return response.blob();
                })
                .then(success)
                .catch(errorFn);
        },

        /**
         * id
         * en
         */
        formatNumber(val, decimals = 0, format = "id") {
            if (val === null || val == "" || val == undefined || isNaN(val)) { val = 0; }
            if (val !== null && val != "" && val != ".") {
                let rawValue = (val).toString();
                let decimal;
                if ((rawValue).includes(".")) {
                    val = ((rawValue).split(".")[0]).replace(/,/g, "");
                    decimal = ((rawValue).split(".")[1]).length == 0 ? "00000" : (rawValue).split(".")[1];
                    decimal = decimal.slice(0, decimals);
                } else {
                    val = (rawValue).replace(/,/g, "");
                    decimal = "00";
                }
                let formattedVal = parseFloat(val).toLocaleString(format);
                val = formattedVal + ((rawValue).includes(".") ? `${decimals == 0 ? "" : "." + decimal}` : "");
            }
            return val;
        },
        number(val) {
            let data = val;
            try {
                data = parseFloat((val.toString()).replace(/,/g, ""));
            } catch (e) {
                data = 0;
            }
            // return data;
            return this.formatNumber(data);
        },
        eventGrid(self, grid, selectedRow) {
            window.addEventListener('keydown', function (evt) {
                // console.info("Baris awal",self[selectedRow]);
                // console.info(self.$route.name.toUpperCase());
                // console.info(self.$options.name.toUpperCase());
                // console.info(self[grid]);
                if (self.$route.name.toUpperCase() === self.$options.name.toUpperCase() && self[grid]) {
                    let baris = document.getElementById(grid + '-' + self[selectedRow]);
                    // console.info("Baris",baris);
                    // console.info("Event",evt.code);
                    switch (evt.code) {
                        case 'ArrowUp':
                            if (self[selectedRow] == -1) {
                                self[selectedRow] = 0;
                                document.getElementById(grid + '-' + self[selectedRow]).focus();
                            } else {
                                if (baris.previousElementSibling != null) {
                                    baris.previousElementSibling.focus();
                                    self[selectedRow]--;
                                }
                            }
                            break;
                        case 'ArrowDown':
                            if (self[selectedRow] == -1) {
                                self[selectedRow] = 0;
                                document.getElementById(grid + '-' + self[selectedRow]).focus();
                            } else {
                                if (baris.nextElementSibling != null) {
                                    baris.nextElementSibling.focus();
                                    self[selectedRow]++;
                                }
                            }
                            break;
                    }
                }
            });
        },
        eventGridStore(self, grid, model) {
            window.addEventListener('keydown', function (evt) {
                if (self[grid]) {
                    // console.info("Ketemu");
                    try {
                        if (model == undefined) {
                            return false;
                        }
                        let baris = document.getElementById(grid + '-' + model.selectedRow);
                        switch (evt.code) {
                            case 'ArrowUp':
                                if (model.selectedRow == -1) {
                                    model.setSelectedRow(0);
                                    document.getElementById(grid + '-' + model.selectedRow).focus();
                                } else {
                                    if (baris.previousElementSibling != null) {
                                        baris.previousElementSibling.focus();
                                        model.setSelectedRow(model.getSelectedRow - 1);
                                    }
                                }
                                break;
                            case 'ArrowDown':
                                if (model.selectedRow == -1) {
                                    model.setSelectedRow(0);
                                    document.getElementById(grid + '-' + model.selectedRow).focus();
                                } else {
                                    if (baris.nextElementSibling != null) {
                                        baris.nextElementSibling.focus();
                                        model.setSelectedRow(model.getSelectedRow + 1);
                                    }
                                }
                                break;
                        }
                    } catch (error) {
                        console.info(error);
                        return false;
                    }
                }
            });
        },
        getGridColumnmAutoWidth(id, kolom, retur) {
            let x = document.getElementById(id);
            // console.info(id, kolom, retur);
            let lebar = 0;
            kolom.map(function (val) {
                if (val.width != '' && val.width != 'auto') {

                    lebar = lebar + parseInt(val.width.replace("px", ""));
                    val['width-numeric'] = parseInt(val.width.replace("px", ""));
                }
            });
            if (!retur) {
                kolom.map(function (val) {
                    if (val.width == 'auto') {
                        lebar = (parseFloat(x.offsetWidth) - parseFloat(lebar) - 73);
                        if (lebar < 199) {
                            lebar = 200;
                        }
                        val.width = lebar + "px";
                        val['width-numeric'] = lebar;
                    }
                });
            } else {
                return (parseFloat(x.offsetWidth) - parseFloat(lebar) - 73);
            }
        },
        incDay(tgl, plus) {
            let tzOff = tgl.getTimezoneOffset() * 60 * 1000,
                t = tgl.getTime(),
                d = new Date(),
                tzOff2;

            t += (1000 * 60 * 60 * 24) * plus;
            d.setTime(t);

            tzOff2 = d.getTimezoneOffset() * 60 * 1000;
            if (tzOff != tzOff2) {
                let diff = tzOff2 - tzOff;
                t += diff;
                d.setTime(t);
            }

            return d;
        },
        lastDayInMonth(bln, thn) {
            return new Date(thn, bln, 0).getDate();
        },
        getNameOfMonth(bln) {
            const bulan = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];
            return bulan[parseInt(bln) - 1];
        },
        base64ToBlob(data, mime) {
            let base64 = window.btoa(window.unescape(encodeURIComponent(data)));
            let bstr = atob(base64);
            let n = bstr.length;
            let u8arr = new Uint8ClampedArray(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new Blob([u8arr], { type: mime });
        },
        downloadText(filename, text, type) {
            // 'data:text/plain;charset=utf-8,'
            let element = document.createElement('a');
            element.setAttribute('href', type + ";charset=utf-8," + encodeURIComponent(text));
            element.setAttribute('download', filename);
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        },
        downloadFile(data, fileName, mime) {
            /*const a = document.createElement("a");
            document.body.appendChild(a);
            a.href = filename;
            a.download = name;
            a.click();
            setTimeout(() => {
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
            }, 0);*/
            let blob = this.base64ToBlob(data, mime);
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            const url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = fileName;
            a.click();
            window.URL.revokeObjectURL(url);
        },
        jsonToQuery(json) {
            let str = "";
            for (let pkey in json) {
                if (str === "") {
                    str = pkey + "=" + json[pkey];
                } else {
                    str = str + "&" + pkey + "=" + json[pkey];
                }
            }
            return str;
        },
        tableToXLS(data) {
            let xlsTemp = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><meta name=ProgId content=Excel.Sheet> <meta name=Generator content="Microsoft Excel 11"><meta http-equiv="Content-Type" content="text/html; charset=UTF-8"><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>${table}</table></body></html>';
            const hasil = xlsTemp.replace("${table}", data);
            return hasil;
        },
        // export default  {
        sethighlight(text, phrase) {
            text = text + "";
            if (phrase)
                text = text.replace(new RegExp('(' + phrase + ')', 'gi'), '<span class="w3-orange">$1</span>');
            return text;
        },
        findObj(obj, key, value) {
            let ketemu = 0;
            for (let pkey in obj) {
                if (obj[pkey][key] === value) {
                    break;
                } else {
                    ketemu++;
                }
            }
            if (obj[ketemu] != undefined) {
                return obj[ketemu];
            } else
                return "-";
        },
        setTerbilang(bilangan) {
            let kalimat = "";
            let angka = new Array('0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0');
            let kata = new Array('', 'Satu', 'Dua', 'Tiga', 'Empat', 'Lima', 'Enam', 'Tujuh', 'Delapan', 'Sembilan');
            let tingkat = new Array('', 'Ribu', 'Juta', 'Milyar', 'Triliun');
            bilangan = bilangan + "";
            let panjang_bilangan = bilangan.length;
            // console.info('Panjang bilangan',panjang_bilangan );

            if (panjang_bilangan > 15) {
                kalimat = "Diluar Batas";
            } else {
                /* mengambil angka-angka yang ada dalam bilangan, dimasukkan ke dalam array */
                for (let i = 1; i <= panjang_bilangan; i++) {
                    angka[i] = bilangan.substr(-(i), 1);
                }

                let i = 1;
                let j = 0;

                /* mulai proses iterasi terhadap array angka */
                while (i <= panjang_bilangan) {
                    let subkalimat = "";
                    let kata1 = "";
                    let kata2 = "";
                    let kata3 = "";

                    /* untuk Ratusan */
                    if (angka[i + 2] != "0") {
                        if (angka[i + 2] === "1") {
                            kata1 = "Seratus";
                        } else {
                            kata1 = kata[angka[i + 2]] + " Ratus";
                        }
                    }

                    /* untuk Puluhan atau Belasan */
                    if (angka[i + 1] != "0") {
                        if (angka[i + 1] === "1") {
                            if (angka[i] === "0") {
                                kata2 = "Sepuluh";
                            } else if (angka[i] === "1") {
                                kata2 = "Sebelas";
                            } else {
                                kata2 = kata[angka[i]] + " Belas";
                            }
                        } else {
                            kata2 = kata[angka[i + 1]] + " Puluh";
                        }
                    }

                    /* untuk Satuan */
                    if (angka[i] != "0") {
                        if (angka[i + 1] != "1") {
                            kata3 = kata[angka[i]];
                        }
                    }

                    /* pengujian angka apakah tidak nol semua, lalu ditambahkan tingkat */
                    if ((angka[i] != "0") || (angka[i + 1] != "0") || (angka[i + 2] != "0")) {
                        subkalimat = kata1 + " " + kata2 + " " + kata3 + " " + tingkat[j] + " ";
                    }

                    /* gabungkan variabe sub kalimat (untuk Satu blok 3 angka) ke variabel kalimat */
                    kalimat = subkalimat + kalimat;
                    i = i + 3;
                    j = j + 1;
                }

                /* mengganti Satu Ribu jadi Seribu jika diperlukan */
                if ((angka[5] === "0") && (angka[6] === "0")) {
                    kalimat = kalimat.replace("Satu Ribu", "Seribu");
                }
            }
            return kalimat;
        },

        setTerbilangEn(number) {
            const first = ['', 'one ', 'two ', 'three ', 'four ', 'five ', 'six ', 'seven ', 'eight ', 'nine ', 'ten ', 'eleven ', 'twelve ', 'thirteen ', 'fourteen ', 'fifteen ', 'sixteen ', 'seventeen ', 'eighteen ', 'nineteen '];
            const tens = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
            const mad = ['', 'thousand', 'million', 'billion', 'trillion'];
            let word = '';

            for (let i = 0; i < mad.length; i++) {
                let tempNumber = number % (100 * Math.pow(1000, i));
                if (Math.floor(tempNumber / Math.pow(1000, i)) !== 0) {
                    if (Math.floor(tempNumber / Math.pow(1000, i)) < 20) {
                        word = first[Math.floor(tempNumber / Math.pow(1000, i))] + mad[i] + ' ' + word;
                    } else {
                        word = tens[Math.floor(tempNumber / (10 * Math.pow(1000, i)))] + '-' + first[Math.floor(tempNumber / Math.pow(1000, i)) % 10] + mad[i] + ' ' + word;
                    }
                }

                tempNumber = number % (Math.pow(1000, i + 1));
                if (Math.floor(tempNumber / (100 * Math.pow(1000, i))) !== 0) word = first[Math.floor(tempNumber / (100 * Math.pow(1000, i)))] + 'hunderd ' + word;
            }
            return word;
        },
        printHtml(textHtml, kertas, bentuk, margin) {
            // portrait landscape
            let idprint = "de-print";
            let element = document.getElementById(idprint);
            if (element != null)
                element.parentNode.removeChild(element);

            let iframe = document.createElement('iframe');
            iframe.setAttribute('id', idprint);
            iframe.setAttribute('name', idprint);
            iframe.setAttribute('frameborder', 0);
            iframe.setAttribute('display', 'none');
            iframe.setAttribute('width', 0);
            iframe.setAttribute('height', 0);

            document.body.appendChild(iframe);
            let newWin = window.frames[idprint];
            textHtml = `<!DOCTYPE html>
            <html lang="en">
                <head>
                    <meta charset="UTF-8">
                    <meta name="viewport" content="width=device-width, initial-scale=1.0">
                    <meta http-equiv="X-UA-Compatible" content="ie=edge">
                    <link rel="stylesheet" href="./assets/css/w3.css">
                    <link rel="stylesheet" href="./assets/css/report.css">
                    <title>Document</title>
                    <style>
                    .pindah-halaman {page-break-after: always;}
                    @media print { 
                        @page { 
                            size: ${kertas} ${bentuk}; 
                            margin : ${margin};
                        }
                    }
                    @font-face {
                        font-family: barcodefont;
                        src: url(./assets/font-code/BarcodeFont.ttf);
                    }
                    .debarcode{
                        font-family: barcodefont;
                    }
                    </style>
                </head>				
                <body onload="window.print()">
                    ${textHtml}
                </body>
            </html>`;
            newWin.document.write(textHtml);
            // console.info(textHtml);
            newWin.document.close();

        },
        printPOS(textHtml) {
            // portrait landscape
            const lebar = 175;
            let idprint = "de-print";
            let element = document.getElementById(idprint);
            if (element != null)
                element.parentNode.removeChild(element);

            let iframe = document.createElement('iframe');
            iframe.setAttribute('id', idprint);
            iframe.setAttribute('name', idprint);
            iframe.setAttribute('frameborder', 0);
            iframe.setAttribute('display', 'none');
            iframe.setAttribute('width', 0);
            iframe.setAttribute('height', 0);

            document.body.appendChild(iframe);
            let newWin = window.frames[idprint];
            textHtml = `<!DOCTYPE html>
            <html lang="en">
                <head>
                    <meta charset="UTF-8">
                    <meta name="viewport" content="width=device-width, initial-scale=1.0">
                    <meta http-equiv="X-UA-Compatible" content="ie=edge">
                    <link rel="stylesheet" href="https://do-event.com/hs48/assets/css/w3.css">
                    <link rel="stylesheet" href="https://do-event.com/hs48/assets/css/report.css">
                    <title>Document</title>
                    <style>
                    body{ width: ${lebar}px; }
                    .pindah-halaman {page-break-after: always;}
                    @media print { 
                        body.receipt { width: ${lebar}px; max-width: ${lebar}px; }
                        @page { 
                            margin : 0 0 0 0;
                        }
                    }
                    @font-face {
                        font-family: barcodefont;
                        src: url(./assets/font-code/BarcodeFont.ttf);
                    }
                    .debarcode{
                        font-family: barcodefont;
                    }
                    </style>
                </head>				
                <body onload="window.print()">
                    ${textHtml}
                </body>
            </html>`;
            newWin.document.write(textHtml);
            // console.info(textHtml);
            newWin.document.close();

        },
        setRomawi(x) {
            let abil = ["", "I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX", "X"];
            if (x < 11)
                return abil[x];
            else if (x < 20)
                return abil[10] + this.setRomawi(x - 10);
            else if (x < 100)
                return this.setRomawi(x / 10) + this.setRomawi(x % 10);
            else if (x < 200)
                return this.setRomawi(x - 100);
            else if (x < 1000)
                return this.setRomawi(x / 100) + this.setRomawi(x % 100);
            else if (x < 2000)
                return this.setRomawi(x - 1000);
            else if (x < 1000000)
                return this.setRomawi(x / 1000) + this.setRomawi(x % 1000);
            else if (x < 1000000000)
                return this.setRomawi(x / 1000000) + this.setRomawi(x % 1000000);
        },
        setFocus(nextElm, waktu) {
            setTimeout(() => {
                document.getElementById(nextElm).focus();
                document.getElementById(nextElm).select();
            }, waktu == undefined ? 200 : waktu);
            return false;
        },
        setCookie(cname, cvalue, exdays) {
            let d = new Date();
            d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
            let expires = "expires=" + d.toUTCString();
            // console.info("Proses kokie 1", cname);
            // console.info("Proses kokie 2", cvalue);
            try {
                document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
            } catch (error) {
                // console.info('error cookie', error);

            }

        },
        getCookie(cname) {
            let name = cname + "=";
            let ca = document.cookie.split(';');
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return "";
        },
        checkCookie() {
            let user = getCookie("username");
            if (user != "") {
                alert("Welcome again " + user);
            } else {
                user = prompt("Please enter your name:", "");
                if (user != "" && user != null) {
                    setCookie("username", user, 365);
                }
            }
        },
        toText(text, val) {
            let valtext = "";
            for (let index = 0; index < (val - (text + "").length); index++) {
                valtext += "0";
            }
            return valtext + text;
        },
        async getAksesMenu() {
            const self = this;
            let urlinduk = vm.$route.path;
            let obj = await self.apiAsync("/tproject/usersmenu/search", {
                "operator": "AND",
                "kondisi": "=;=",
                "field": "users_menu.users_id;menu_url",
                "fieldvalue": this.DeCompresString(localStorage.username) + ";" + urlinduk,
                "limit": 1,
                "offset": 0,
                "fieldselect": "users_menu_add as add, users_menu_edit as edit, users_menu_delete as delete, users_menu_print as print, users_menu.id",
            });
            // console.info(obj);
            if (parseInt(obj.ttlrec) > 0) {
                let objd = await self.apiAsync("/tproject/usersmenu/search", {
                    "operator": "AND",
                    "kondisi": "=",
                    "field": "users_menu.id",
                    "fieldvalue": obj.rec[0]["id"] + "",
                    "limit": 100,
                    "offset": 0,
                    "fieldselect": "json_array_elements(users_menu_detail->'menu')->>'url' as url, json_array_elements(users_menu_detail->'menu')->>'status' as status",
                });
                obj.rec[0]["detail"] = objd.rec;
                return obj.rec[0];
            }
            return undefined;
        },
        checkEventAkses(menu, akses, detail, pesan) {
            if (detail == "F") {
                if (akses == "A") {
                    if (menu.add != "T") {
                        if (pesan == undefined)
                            this.toast.error("Tidak punya hak akses");
                        return false;
                    }
                }
                if (akses == "E") {
                    if (menu.edit != "T") {
                        if (pesan == undefined)
                            this.toast.error("Tidak punya hak akses");
                        return false;
                    }
                }
                if (akses == "D") {
                    if (menu.delete != "T") {
                        if (pesan == undefined)
                            this.toast.error("Tidak punya hak akses");
                        return false;
                    }
                }
                if (akses == "P") {
                    if (menu.delete != "T") {
                        if (pesan == undefined)
                            this.toast.error("Tidak punya hak akses");
                        return false;
                    }
                }
            } else {
                let ketemu = false;
                if (menu.detail != undefined) {
                    for (let index = 0; index < menu.detail.length; index++) {
                        const element = menu.detail[index];
                        if (element["url"] == akses) {
                            if (element["status"] == "T") {
                                ketemu = true;
                            }
                        }
                    }
                }
                if (!ketemu) {
                    if (pesan == undefined)
                        this.toast.error("Tidak punya hak akses");
                    return false;
                }
            }
            return true;
        },
        dragElement(elmnt) {
            // console.info('elmnt',elmnt);
            if (elmnt == null) {
                return false;
            }
            let screenWidth = window.innerWidth;
            let pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
            let result = -1;
            let lebar = "";
            let selisih = 0;
            result = elmnt.style.width.indexOf("px");
            if (result != -1) {
                lebar = elmnt.style.width.replace("px", "");
                selisih = (screenWidth - parseFloat(lebar)) / 2;
            }
            result = elmnt.style.width.indexOf("%");
            if (result != -1) {
                lebar = elmnt.style.width.replace("%", "");
                lebar = (lebar / 100) * screenWidth;
                selisih = (screenWidth - parseFloat(lebar)) / 2;
                elmnt.style.right = selisih + "px";
            }
            if (selisih > 0) {
                elmnt.style.left = selisih + "px";
                if (document.getElementById(elmnt.id + "header")) {
                    document.getElementById(elmnt.id + "header").onmousedown = dragMouseDown;
                } else {
                    elmnt.onmousedown = dragMouseDown;
                }

                function dragMouseDown(e) {
                    e = e || window.event;
                    e.preventDefault();
                    pos3 = e.clientX;
                    pos4 = e.clientY;
                    document.onmouseup = closeDragElement;
                    document.onmousemove = elementDrag;
                }

                function elementDrag(e) {
                    e = e || window.event;
                    e.preventDefault();
                    pos1 = pos3 - e.clientX;
                    pos2 = pos4 - e.clientY;
                    pos3 = e.clientX;
                    pos4 = e.clientY;
                    elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
                    elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
                }

                function closeDragElement() {
                    document.onmouseup = null;
                    document.onmousemove = null;
                }
            } else {
                elmnt.style.position = "relative";
            }

        },
        queryObject(obj, key, filter) {
            let hit = 0;
            let hsl = [];
            if (obj.length == 0) {
                return hsl;
            }
            obj.map(function (val) {
                hit = 0;
                if (val[key].toUpperCase().indexOf(filter.toUpperCase()) > -1) {
                    hit = 1;
                }
                if (hit == 1) {
                    hsl.push(val);
                }
            });
            return hsl;
        },
        closeModal(id) {
            try {
                // console.info("modal"+id);
                document.getElementById('modalBody' + id).classList.add("spaceOutDown");
                document.getElementById('modalBody' + id).classList.remove("spaceInDown"); //puffIn
                setTimeout(() => {
                    document.getElementById('modal' + id).style.display = 'none';
                }, 500);
            } catch (error) {
                console.info(error);
            }
        },
        showModal(id) {
            try {
                document.getElementById('modalBody' + id).classList.add("spaceInDown");
                document.getElementById('modalBody' + id).classList.remove("spaceOutDown");
                setTimeout(() => {
                    document.getElementById('modal' + id).style.display = 'block';
                }, 100);
            } catch (error) {
                console.info(error);
            }
        },
        buttonClick(event, fn) {
            let el = event.target;
            if (!["BUTTON", "A"].includes(event.target.tagName)) {
                el = event.target.parentElement;
            }
            el.classList.add("magictime");
            el.classList.add("boingInUp");
            setTimeout(() => {
                el.classList.remove("magictime");
                el.classList.remove("boingInUp");
                setTimeout(() => {
                    if (fn != undefined && typeof fn === 'function') {
                        fn();
                    } else {
                        fn;
                    }
                }, 90);
            }, 90);
            event.preventDefault();
            return false;
        },
        terb_depan(uang) {
            var sub = '';
            if (uang == 1) { sub = 'Satu ' } else
                if (uang == 2) { sub = 'Dua ' } else
                    if (uang == 3) { sub = 'Tiga ' } else
                        if (uang == 4) { sub = 'Empat ' } else
                            if (uang == 5) { sub = 'Lima ' } else
                                if (uang == 6) { sub = 'Enam ' } else
                                    if (uang == 7) { sub = 'Tujuh ' } else
                                        if (uang == 8) { sub = 'Delapan ' } else
                                            if (uang == 9) { sub = 'Sembilan ' } else
                                                if (uang == 0) { sub = '  ' } else
                                                    if (uang == 10) { sub = 'Sepuluh ' } else
                                                        if (uang == 11) { sub = 'Sebelas ' } else
                                                            if ((uang >= 11) && (uang <= 19)) { sub = this.terb_depan(uang % 10) + 'Belas '; } else
                                                                if ((uang >= 20) && (uang <= 99)) { sub = this.terb_depan(Math.floor(uang / 10)) + 'Puluh ' + this.terb_depan(uang % 10); } else
                                                                    if ((uang >= 100) && (uang <= 199)) { sub = 'Seratus ' + this.terb_depan(uang - 100); } else
                                                                        if ((uang >= 200) && (uang <= 999)) { sub = this.terb_depan(Math.floor(uang / 100)) + 'Ratus ' + this.terb_depan(uang % 100); } else
                                                                            if ((uang >= 1000) && (uang <= 1999)) { sub = 'Seribu ' + this.terb_depan(uang - 1000); } else
                                                                                if ((uang >= 2000) && (uang <= 999999)) { sub = this.terb_depan(Math.floor(uang / 1000)) + 'Ribu ' + this.terb_depan(uang % 1000); } else
                                                                                    if ((uang >= 1000000) && (uang <= 999999999)) { sub = this.terb_depan(Math.floor(uang / 1000000)) + 'Juta ' + this.terb_depan(uang % 1000000); } else
                                                                                        if ((uang >= 100000000) && (uang <= 999999999999)) { sub = this.terb_depan(Math.floor(uang / 1000000000)) + 'Milyar ' + this.terb_depan(uang % 1000000000); } else
                                                                                            if ((uang >= 1000000000000)) { sub = this.terb_depan(Math.floor(uang / 1000000000000)) + 'Triliun ' + this.terb_depan(uang % 1000000000000); }
            return sub;
        },
        terb_belakang(t) {
            if (t.length == 0) {
                return '';
            }
            return t
                .split('0').join('Nol ')
                .split('1').join('Satu ')
                .split('2').join('Dua ')
                .split('3').join('Tiga ')
                .split('4').join('Empat ')
                .split('5').join('Lima ')
                .split('6').join('Enam ')
                .split('7').join('Tujuh ')
                .split('8').join('Delapan ')
                .split('9').join('Dembilan ');
        },
        terbilang(nangka) {
            var
                v = 0,
                sisa = 0,
                tanda = '',
                tmp = '',
                sub = '',
                subkoma = '',
                p1 = '',
                p2 = '',
                pkoma = 0;
            if (nangka > 999999999999999999) {
                return 'Buset dah buanyak amat...';
            }
            v = nangka;
            if (v < 0) {
                tanda = 'Minus ';
            }
            v = Math.abs(v);
            tmp = v.toString().split('.');
            p1 = tmp[0];
            p2 = '';
            if (tmp.length > 1) {
                p2 = tmp[1];
            }
            v = parseFloat(p1);
            sub = this.terb_depan(v);
            /* sisa = parseFloat('0.'+p2);
            subkoma = terb_belakang(sisa); */
            subkoma = this.terb_belakang(p2);
            if (subkoma != "")
                sub = tanda + sub.replace('  ', ' ') + 'Koma ' + subkoma.replace('  ', ' ');
            return sub.replace('  ', ' ');
        },
        checkEnter(e) {
            e = e || event;
            var txtArea = /textarea/i.test((e.target || e.srcElement).tagName);
            return txtArea || (e.keyCode || e.which || e.charCode || 0) !== 13;
        },
        saveHistoryFind(name, model) {
            if (model.filters.find.length > 0) {
                localStorage[name] = this.StringCompress(JSON.stringify(model.filters.find));
            } else {
                localStorage[name] = JSON.stringify([]);
            }
        },
        loadHistoryFind(name, model) {
            const finddata = localStorage[name];
            if (finddata != undefined) {
                const dec = this.DeCompresString(finddata);
                const jpas = JSON.parse(dec != null ? dec : "[]");
                // console.info('jpas.length', jpas);

                if (jpas.length > 0) {
                    if (jpas[0]["fieldValue"] == "" || jpas[0]["fieldValue"] == undefined) {
                        jpas[0]["kondisi"] = "";
                    } else {
                        jpas[0]["kondisi"] = "like";
                    }
                }
                model.filters.find = jpas;
            }
        },
        selectFile(contentType, multiple) {
            return new Promise(resolve => {
                let input = document.createElement("input");
                input.type = "file";
                input.multiple = multiple;
                input.accept = contentType;
                // eslint-disable-next-line no-unused-vars
                input.onchange = _ => {
                    let files = Array.from(input.files);
                    if (multiple) {
                        resolve(files);
                    } else {
                        resolve(files.first);
                    }
                };
                input.click();
            });
        },
        async fileToBlob(fl) {
            return await new Blob([new Uint8Array(await fl.arrayBuffer())], { type: fl.type });
        },
        file2Base64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
                    if ((encoded.length % 4) > 0) {
                        encoded += '='.repeat(4 - (encoded.length % 4));
                    }
                    resolve(encoded);
                };
                reader.onerror = error => reject(error);
            });
        }
    }
}
